/*
  마이렛미업 내 스터디 분석 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setRankingPopup } from "../../store/modules/popup"
import { Radar } from "react-chartjs-2"
import Swiper from "react-id-swiper"
import "swiper/scss"
import SEO from "../../components/seo"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import SummaryBox from "../../components/common/SummaryBox"
import BarChart from "../../components/common/BarChart"
import RankingPopup from "../../popups/RankingPopup"
import img_my_analysis_right from "../../images/img_my_analysis_right.svg"
import img_my_analysis_btn from "../../images/img_my_analysis_btn.svg"
import img_swiper_left from "../../images/img_swiper_left.svg"
import img_swiper_right from "../../images/img_swiper_right.svg"
import playCon from "../../images/playCon.png"
import temp_profile from "../../images/editicon.png"
import PrivateRoute from "../../components/privateRoute"
import MyContainer from "../../containers/MyContainer"
import { timeFn, rnMessageListener, dataCheckFn } from "../../utils/util"
import ExploringContainer from "../../containers/ExploringContainer"

import InfoAIPopup from "../../popups/InfoAIPopup"
import InfoRulePopup from "../../popups/InfoRulePopup"
import InfoBrainPopup from "../../popups/InfoBrainPopup"

import moment from "moment"
import { debounce } from "lodash"
import { navigate } from "gatsby-link"
//마이렛미업 내 공부 분석

//ai & 규칙 차트 옵션
const RADAR_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: false,
      },
      pointLabels: {
        font: {
          size: "14px",
          lineHeight: "20px",
        },
      },
      max: 7,
      min: 0,
      // suggestedMin: 7, // 최소값
      suggestedMax: 8, // 최고값
      ticks: {
        count: 8,

        display: false, // 단위 텍스트 지움
      },
    },
  },
}

const Analysis = props => {
  const {
    getMyAnalysisAi,
    myAi,
    getMyAnalysisRule,
    myRule,
    setChartOption,
    myLetmeupData,
    myProfile,
    myStudyTime,
    getMyStatisticsStudyimeMe,
    getMyLetmeupStatistics,
    getStudyhome,
    exploringData,
    getBrainMonitorings,
    myBrain,
  } = props

  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [studyTimes, setSudyTimes] = useState([])
  const [planTimes, setPlanTimes] = useState([])
  const [labels, setLabels] = useState([])

  //popup
  const [openAi, setOpenAi] = useState(false)
  const [openRule, setOpenRule] = useState(false)
  const [openBrain, setOpenBrain] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  //data get
  const getData = async () => {
    await getMyStatisticsStudyimeMe()
    await getMyAnalysisRule()
    await getMyAnalysisAi()
    await getMyLetmeupStatistics({ period: "TODAY" })
    await getBrainMonitorings()

    await getStudyhome({ region3: "", tag: "" })
  }
  //data get 후 데이터 세팅
  useEffect(() => {
    if (Object.keys(myLetmeupData).length != 0) {
      setLabels(myLetmeupData.chart.labels)
      setSudyTimes(timeFn(myLetmeupData.chart.studyTimes, "arr"))
      setPlanTimes(timeFn(myLetmeupData.chart.planTimes, "arr"))

      setChartOption({
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // 차트 내 세로줄 제거
            },
          },
          y: {
            min: 0,
            // max: 24,
            ticks: {
              // stepSize: 4,
              color: "#000000",
              callback: function (value) {
                return value + "시간"
              },
            },
            grid: {
              drawBorder: false, // y축 세로줄 제거
            },
          },
        },
      })
    }
  }, [myLetmeupData])

  //차트데이터
  const BAR_DATA = {
    labels: labels,
    datasets: [
      {
        label: "공부시간",
        data: studyTimes,
        borderColor: "#28D7D2",
        backgroundColor: "#28D7D2",
      },
      {
        label: "목표시간",
        data: planTimes,
        borderColor: "#D1D5DB",
        backgroundColor: "#D1D5DB",
      },
    ],
  }
  // ai 데이터
  const RADAR_DATA_AI = {
    labels: Object.keys(myAi).length > 0 ? myAi.chart.labels : [],
    datasets: [
      {
        label: "March",
        backgroundColor: "rgba(0, 215, 221, 0.4)",
        borderColor: "rgba(0, 215, 221, 0)",
        pointBackgroundColor: "rgba(0, 215, 221, 0)",
        poingBorderColor: "rgba(0, 215, 221, 0)",
        pointHoverBackgroundColor: "rgba(0, 215, 221, 0.4)",
        pointHoverBorderColor: "rgba(0, 215, 221, 0.4)",
        data: Object.keys(myAi).length > 0 ? myAi.chart.scores : [],
      },
    ],
  }

  //학습규칙 데이터
  const RADAR_DATA_RULE = {
    labels: Object.keys(myRule).length > 0 ? myRule.labels : [],
    datasets: [
      {
        label: "March",
        backgroundColor: "rgba(0, 215, 221, 0.4)",
        borderColor: "rgba(0, 215, 221, 0)",
        pointBackgroundColor: "rgba(0, 215, 221, 0)",
        poingBorderColor: "rgba(0, 215, 221, 0)",
        pointHoverBackgroundColor: "rgba(0, 215, 221, 0.4)",
        pointHoverBorderColor: "rgba(0, 215, 221, 0.4)",
        data: Object.keys(myRule).length > 0 ? [...myRule.scores.map(i => i || 0)] : [],
      },
    ],
  }

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout title="내 공부 분석">
        <InfoAIPopup open={openAi} onClose={() => setOpenAi(false)} />
        <InfoRulePopup open={openRule} onClose={() => setOpenRule(false)} />
        <InfoBrainPopup open={openBrain} onClose={() => setOpenBrain(false)} />
        <SEO title="마이렛미업" />
        <RankingPopup title="누적 공부시간 전체 랭킹" list={exploringData.userStudyStatistics} />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-analysis-div-1">
              <p className="my-analysis-title">
                <Desktop>내 공부 분석</Desktop>
                <Mobile>오늘 공부한 시간</Mobile>
              </p>

              <div className="my-analysis-div-1-inner">
                <Desktop>
                  <p className="text1">{`내가 오늘 공부한 시간  /  내가 계획한 목표 시간`}</p>
                  <p className="text2">
                    <span>
                      {studyTimes.length > 0 &&
                        myLetmeupData.chart.studyTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[0] +
                          "시간 " +
                          myLetmeupData.chart.studyTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[1] +
                          "분"}
                    </span>
                    {`  /  `}
                    {studyTimes.length > 0 &&
                      myLetmeupData.chart.planTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[0] +
                        "시간 " +
                        myLetmeupData.chart.planTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[1] +
                        "분"}
                  </p>
                </Desktop>
                <Mobile>
                  <div className="mobile-only">
                    <div>
                      <p className="text2">
                        <span>
                          {studyTimes.length > 0 &&
                            myLetmeupData.chart.studyTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[0] +
                              "시간 " +
                              myLetmeupData.chart.studyTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[1] +
                              "분"}
                        </span>
                      </p>
                      <p className="text1">오늘 공부한 시간</p>
                    </div>
                    <div className="division" />
                    <div>
                      <p className="text2">
                        {studyTimes.length > 0 &&
                          myLetmeupData.chart.planTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[0] +
                            "시간 " +
                            myLetmeupData.chart.planTimes[moment().format("e") == 0 ? 6 : moment().format("e") - 1].split(":")[1] +
                            "분"}
                      </p>
                      <p className="text1">오늘 목표한 시간</p>
                    </div>
                  </div>
                </Mobile>

                <SummaryBox data={myLetmeupData} />

                <BarChart data={BAR_DATA} />
              </div>
            </div>

            <div className="my-analysis-div-2">
              <p className="my-analysis-sub-title">누적 공부시간 랭킹</p>
              <p className="update-text">최종 업데이트 {moment().format("YYYY년MM월DD일(ddd) HH:mm")}</p>
              <div className="my-analysis-div-inner">
                <div className="ranking-item">
                  <div>
                    <p>나</p>
                    <p>{myStudyTime.rank}등</p>
                    <p>{myProfile.nickname != null && myProfile.nickname != "" ? myProfile.nickname : "nickname"}</p>
                  </div>
                  <p>{myStudyTime.totalStudyTime}</p>
                </div>
                <div className="my-analysis-bottom-view">
                  <p>
                    <span>{myStudyTime.keyword}</span> 키워드 그룹의 평균 공부 시간은 <span>{myStudyTime.averageStudyTime || 0}</span>
                    {`시간이며\n상위 ${myStudyTime.range || 0}% 순위에 있습니다.`}
                  </p>
                  <Desktop>
                    <button className={`btn btn-primary font-white`} onClick={() => dispatch(setRankingPopup(true))}>
                      전체 누적 공부 시간 랭킹 보기
                    </button>
                  </Desktop>
                  <Mobile>
                    <div>
                      <button className={`btn`} onClick={() => dispatch(setRankingPopup(true))}>
                        전체 랭킹 보기
                        <img src={img_my_analysis_right} alt="right img" />
                      </button>
                    </div>
                  </Mobile>
                </div>
              </div>
            </div>

            <div className="my-analysis-div-3">
              <p className="my-analysis-sub-title">AI 민감도 분석</p>
              <div className="my-analysis-div-inner">
                <div className="my-analysis-radar-chart-view">
                  <Radar data={RADAR_DATA_AI} options={RADAR_OPTIONS} />
                </div>
                <div className="my-analysis-bottom-view">
                  <p>
                    AI 민감도 <span>레벨 {dataCheckFn(myAi) && myAi.aiLevel}</span>로 전체 렛미업 학습자 상위 {dataCheckFn(myAi) && myAi.range}%
                    순위에 있습니다.
                  </p>
                  <Desktop>
                    <button onClick={() => setOpenAi(true)} className={`btn btn-primary font-white`}>
                      렛미업 AI 민감도란?
                    </button>
                  </Desktop>
                  <Mobile>
                    <div>
                      <button onClick={() => setOpenAi(true)} className={`btn`}>
                        AI민감도
                        <img src={img_my_analysis_btn} alt="! img" />
                      </button>
                    </div>
                  </Mobile>
                </div>
              </div>
            </div>

            <div className="my-analysis-div-4">
              <p className="my-analysis-sub-title">렛미업 학습 규칙 준수 </p>
              <div className="my-analysis-div-inner">
                <div className="my-analysis-radar-chart-view">
                  <Radar data={RADAR_DATA_RULE} options={RADAR_OPTIONS} />
                </div>
                <div className="my-analysis-bottom-view">
                  <p>
                    렛미업 학습 규칙 준수 <span>레벨 {dataCheckFn(myRule) && myRule.learnLevel}</span>로 전체 렛미업 학습자 상위{" "}
                    {(dataCheckFn(myRule) && myRule.range) || 0}% 순위에 있습니다.
                  </p>
                  <Desktop>
                    <button onClick={() => setOpenRule(true)} className={`btn btn-primary font-white`}>
                      렛미업 학습 규칙이란?
                    </button>
                  </Desktop>
                  <Mobile>
                    <div>
                      <button onClick={() => setOpenRule(true)} className={`btn`}>
                        렛미업 학습규칙
                        <img src={img_my_analysis_btn} alt="! img" />
                      </button>
                    </div>
                  </Mobile>
                </div>
              </div>
            </div>

            <div className="my-analysis-div-5">
              <p className="my-analysis-sub-title">스터디 뇌파 모니터링 </p>
              <div className="my-analysis-div-inner">
                <div className="contents-view">
                  <button
                    className="btn my-analysis-swiper-left"
                    onClick={() => {
                      if (activeIndex === 0) return
                      setActiveIndex(prev => prev - 1)
                    }}
                  >
                    <img src={img_swiper_left} alt="left img" />
                  </button>
                  {dataCheckFn(myBrain) && (
                    <button
                      className="btn my-analysis-swiper-right"
                      onClick={() => {
                        if (activeIndex === myBrain.brainMonitorings.length - 1) return
                        setActiveIndex(prev => prev + 1)
                      }}
                    >
                      <img src={img_swiper_right} alt="right img" />
                    </button>
                  )}
                  <div className="my-analysis-swiper-wrapper">
                    <Swiper shouldSwiperUpdate slidesPerView="auto" activeSlideKey={activeIndex + ""}>
                      {dataCheckFn(myBrain) &&
                        myBrain.brainMonitorings.map((i, idx) => (
                          <div className="contents-item mt-2" style={{ marginRight: "22px" }} key={idx}>
                            <div className="progress mb-2">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow="60"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${i.attention}%` }}
                              >
                                집중력 {i.attention}%{/* <br /> 이완도{i.meditation}% */}
                              </div>
                            </div>
                            {/* <img src={temp_profile} alt="contents img" /> */}
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ background: "#D1D5DB", width: "194px", height: "116px", borderRadius: "6px" }}
                            >
                              <img src={playCon}></img>
                            </div>
                            <p>{i.useType == "STUDY" ? i.studyTitle : i.contentTitle}</p>
                            <p>{i.useTime}</p>
                          </div>
                        ))}
                    </Swiper>
                  </div>
                </div>
                <div className="my-analysis-bottom-view">
                  <p>
                    스터디 뇌파 모니터링의 누적 사용시간은 <span>{dataCheckFn(myBrain) ? myBrain.totalTime : "00:00"}</span> 입니다.
                  </p>
                  <Desktop>
                    <button onClick={() => setOpenBrain(true)} className={`btn btn-primary font-white`}>
                      렛미업 스터디 뇌파 모니터링이란?
                    </button>
                  </Desktop>
                  <Mobile>
                    <div>
                      <button onClick={() => setOpenBrain(true)} className={`btn`}>
                        렛미업 스터디 뇌파 모니터링
                        <img src={img_my_analysis_btn} alt="! img" />
                      </button>
                    </div>
                  </Mobile>
                </div>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default ExploringContainer(MyContainer(Analysis))
