import moment from "moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setRankingPopup } from "../store/modules/popup"
import LayerPopupLayout from "./LayerPopupLayout"
import UP from "../images/UP.svg"
import DOWN from "../images/DOWN.svg"
import KEEP from "../images/KEEP.svg"
import img_ranking_is_mine_border from "../images/img_ranking_is_mine_border.svg"
const RankingPopup = ({ type = "", title = "{제목}", list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] }) => {
  const dispatch = useDispatch()
  const rankingPopup = useSelector(state => state.popup.rankingPopup)
  const closePopup = () => {
    dispatch(setRankingPopup(false))
  }

  return (
    <LayerPopupLayout open={rankingPopup} onClose={closePopup} isCloseBtn isMCloseBtn transparency zIndex={101}>
      <div className="popup-ranking-container">
        <p className="popup-ranking-title">{title}</p>
        <div className="popup-ranking-list-view">
          {list.map((i, idx) => (
            <div key={idx} className="popup-ranking-list-item">
              {i.isMine == 1 ? <img className="is-mine" src={img_ranking_is_mine_border} alt="ismine img" /> : null}
              <p className="no">{idx + 1}</p>
              <p className="title">{i.title || i.nickname || i.tag}</p>
              {type == "" ? (
                <p className="time">{i.totalTime}</p>
              ) : (
                <div className="up-down time">
                  <p>{i.rankingFluctuation == 0 ? "-" : i.rankingFluctuation}</p>
                  <img src={i.rankingUpDown == "KEEP" ? KEEP : i.rankingUpDown == "UP" ? UP : DOWN}></img>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="popup-ranking-close-btn-view">
          <button className="btn btn-primary font-white popup-ranking-close-btn" onClick={closePopup}>
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default RankingPopup
