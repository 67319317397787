import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Desktop, Mobile } from "../components/layouts/mediaQuery"

import img_popup_close_gray from "../images/img_popup_close_gray.svg"

import LayerPopupLayout from "./LayerPopupLayout"

import { navigate } from "gatsby-link"

const InfoAIpopup = props => {
  const { open, onClose } = props
  const dispatch = useDispatch()

  //닫기
  const closePopup = () => {
    onClose()
  }
  //저장fn

  return (
    <LayerPopupLayout open={open} onClose={closePopup} transparency={true} isMCloseBtn zIndex={105}>
      <div className="popup-info-ai-container">
        <div className="close-button-view">
          <button className="btn"> </button>
          <button className="btn" onClick={closePopup}>
            <img src={img_popup_close_gray} alt="popup close img" />
          </button>
        </div>
        <Desktop>
          <div className="d-flex justify-content-center mt-1">
            <button style={{ width: "220px", height: "46px" }} className="btn btn-primary font-white">
              렛미업 AI 민감도란?
            </button>
          </div>
        </Desktop>
        <Mobile>
          <div className="d-flex mt-1">
            <div className="mo-title">AI 민감도란?</div>
          </div>
        </Mobile>
        <div className="content-div">
          <div className="border-box"></div>
        </div>
        <div className="info-button-box">
          <button onClick={closePopup} className="btn btn-primary font-white">
            확인
          </button>
        </div>
      </div>
    </LayerPopupLayout>
  )
}

export default InfoAIpopup
