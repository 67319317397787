import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { getStudyhomeAction, postPwReqAction } from "../store/modules/exploring"

// redux state > component mapping
const mapStateToProps = state => ({
  exploringData: state.exploring.exploringData,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  getStudyhome: param => dispatch(getStudyhomeAction(param)),
  postPwReq: param => dispatch(postPwReqAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
