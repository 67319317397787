import React from "react"
import img_summary_box_up from "../../images/img_summary_box_up.svg"
import img_summary_box_down from "../../images/img_summary_box_down.svg"
import img_polygon_up from "../../images/img_polygon_up.svg"
import img_polygon_down from "../../images/img_polygon_down.svg"
import { timeFn } from "../../utils/util"

const SummaryBox = ({ data }) => {
  return (
    Object.keys(data).length != 0 && (
      <div className="common-summary-box-container">
        <div className="common-summary-box-item">
          <img src={parseInt(data.changePercentAccumulatedStudyTime) >= 0 ? img_summary_box_up : img_summary_box_down} alt="status icon img" />
          <div>
            <div>
              <p>{parseInt(timeFn(data.accumulatedStudyTime))}h</p>
              <img src={parseInt(data.changePercentAccumulatedStudyTime) >= 0 ? img_polygon_up : img_polygon_down} alt="polygon img" />
              <span className={data.changePercentAccumulatedStudyTime >= 0 ? "plus" : "minus"}>
                {parseInt(data.changePercentAccumulatedStudyTime)}%
              </span>
            </div>
            <p>누적 공부시간</p>
          </div>
        </div>
        <div className="common-summary-box-item">
          <img src={parseInt(data.changePercentAverageStudyTime) >= 0 ? img_summary_box_up : img_summary_box_down} alt="status icon img" />
          <div>
            <div>
              <p>{parseInt(timeFn(data.averageStudyTime))}h</p>
              <img src={parseInt(data.changePercentAverageStudyTime) >= 0 ? img_polygon_up : img_polygon_down} alt="polygon img" />
              <span className={data.changePercentAverageStudyTime >= 0 ? "plus" : "minus"}>{parseInt(data.changePercentAverageStudyTime)}%</span>
            </div>
            <p>평균 공부시간</p>
          </div>
        </div>
        <div className="common-summary-box-item">
          <img src={img_summary_box_up} alt="status icon img" />
          <div>
            <div>
              <p>{data.percent}%</p>
            </div>
            <p>목표달성</p>
          </div>
        </div>
      </div>
    )
  )
}

export default SummaryBox
