import React from "react"
import { Bar } from "react-chartjs-2"
import { useSelector } from "react-redux"
import MyContainer from "../../containers/MyContainer"
import { Desktop, Mobile } from "../layouts/mediaQuery"

const DESKTOP_OPTION = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // 차트 내 세로줄 제거
      },
      stacked: false,
      ticks: {
        color: "#000000",
      },
    },
    y: {
      min: 0,
      // max: 24,
      ticks: {
        // stepSize: 4,
        color: "#000000",
        callback: function (value) {
          return value + "시간" // 모바일 일 때 "시간" 제거
        },
      },
      grid: {
        drawBorder: false, // y축 세로줄 제거
      },
    },
  },
}

const MOBILE_OPTION = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false, // 차트 내 세로줄 제거
      },
      stacked: true,
      ticks: {
        color: "#898A8D",
      },
    },
    y: {
      min: 0,
      // max: 24,
      ticks: {
        stepSize: 4,
        color: "#898A8D",
        callback: function (value) {
          return value < 10 ? "0" + value : value // 모바일 일 때 "시간" 제거
        },
      },
      grid: {
        drawBorder: false, // y축 세로줄 제거
      },
    },
  },
}

const BarChart = ({ data }) => {
  const chatOption = useSelector(state => state.my.chatOption)

  return (
    <div className="common-bar-chart-view">
      <Desktop>
        <Bar data={{ ...data, datasets: data.datasets.map(i => ({ ...i, borderRadius: 4, barThickness: 18 })) }} options={chatOption} />
      </Desktop>
      <Mobile>
        <Bar
          data={{
            ...data,
            labels: data.labels.map(i => (i.length === 3 ? i.substr(0, 1) : i)),
            datasets: data.datasets.map(i => ({ ...i, borderRadius: 3, barThickness: 14 })),
          }}
          options={MOBILE_OPTION}
        />
      </Mobile>
      <div>
        <div>
          <span className="sky" />
          <p>공부시간</p>
        </div>
        <div>
          <span className="gray" />
          <p>목표시간</p>
        </div>
      </div>
    </div>
  )
}

export default MyContainer(BarChart)
